define("discourse/plugins/discourse-bcc/discourse/templates/components/bcc-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.bccAvailable}}
    <div class="bcc-checkbox">
      <label>
        <Input @type="checkbox" @checked={{this.checked}} />
        {{i18n "discourse_bcc.use_bcc"}}
      </label>
    </div>
  {{/if}}
  */
  {
    "id": "OiKtSPwl",
    "block": "[[[41,[30,0,[\"bccAvailable\"]],[[[1,\"  \"],[10,0],[14,0,\"bcc-checkbox\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n      \"],[1,[28,[35,2],[\"discourse_bcc.use_bcc\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-bcc/discourse/templates/components/bcc-checkbox.hbs",
    "isStrictMode": false
  });
});